<template>
<div class="company-list">

  <van-search
    class="input"
    v-model="value"
    placeholder="搜索关联企业"
    shape="round"
    @input="enterpriseSearch"
    background="#F6F6F6"/>


  <van-index-bar v-show="emptybool"  highlight-color="#666666">
<!--    <div v-for="item in companyList" :key="item.type" class="bar-item">-->
    <div v-for="item in companyList" :key="item.type" v-show="item.list.length>0" class="bar-item">
    <van-index-anchor :index="item.type" class="item-index" />
    <van-cell v-for="j in item.list" :key="j.id" :title="j.companyName" @click="toAssociation(j.id)" class="item-cell" />
    </div>
  </van-index-bar>


  <van-empty
    v-show="!emptybool"
    class="custom-image"
    :image="nonecontent"
    description="暂无内容"
  />

</div>
</template>

<script>
import { getCompanyList } from '@/api/res-user'
import { Toast } from 'vant'
import nonecon from '@/assets/img/nonecontent1.png'
export default {
  name: 'company_list',
  data () {
    return {
      indexList: ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
      companyList:[], //公司数组
      value:'',
      companyData:[], //公司数据
      companyState:[],  //公司数组模板
      companyListData:[],
      nonecontent:nonecon,
    }
  },
  mounted () {
    document.title = '关联企业'
    this.indexList.forEach(item=>{
      let i={
        type:item,
        list:[],
        listLen:'',
      }
      this.companyListData.push(i)
    })

    this.loadgetCompanyList()

  },



  computed:{
    emptybool:function (){
      let data=true
      for(let i=0;i<this.companyList.length; i++){
        if(this.companyList[i].listLen!=='' && Number(this.companyList[i].listLen)>0){
          data=true
          break
        }else {
          data=false
        }
      }
      return data
    }
  },

  methods:{

    loadgetCompanyList(){

      Toast.loading({
        message: '加载中',
        forbidClick: true,
      });
      getCompanyList({companyName: '',isExcludeMeCompany:true}).then((res) => {

        this.companyData=res

        this.companyChange()

        this.companyListchange(res)

        for (let k of res){
          this.companyListData.forEach(i=>{
            if(k.companyNameInitial && i.type){
            if( k.companyNameInitial.toUpperCase()===i.type.toUpperCase()){
              if(i.list.indexOf(k.companyName)===-1){
                let data={
                  companyName:k.companyName,
                  id:k.id
                }
                i.list.push(data)
              }
              i.listLen= i.list.length
            }
            }
          })
        }


        Toast.clear()
      })
    },

    companyChange(){
      this.indexList.forEach(item=>{
        let listitem={
          type:item,
          list:[],
          listLen:'',
        }
        this.companyList.push(listitem)
      })
    },


    companyListchange(res){
      for (let k of res){
        this.companyList.forEach(i=>{
          if(k.companyNameInitial && i.type){
          if(k.companyNameInitial.toUpperCase()===i.type.toUpperCase()){
            if(i.list.indexOf(k.companyName)===-1){
              let data={
                companyName:k.companyName,
                id:k.id
              }
              i.list.push(data)
            }
            i.listLen= i.list.length
           /*  i.list=i.list.filter(item=>{
              return item.listLen!=='' && Number(item.listLen)>0
            }) */

          }}
        })
       /*  console.log(this.companyList,'this.companyList=====')
        this.companyList=this.companyList.filter(item=>{
          return item.listLen!=='' && Number(item.listLen)>0
        })
        console.log(this.companyList,'this.companyList') */
      }
    },


    toAssociation(id){
      this.$router.push({
          path: "/verified/association_company",
          query: {id},
      }
      )
    },


    enterpriseSearch() {
      let j=[]

      if (this.value !== '') {

        this.companyData.forEach(item=>{
          if(item.companyName.indexOf(this.value)!==-1){
            j.push(item)
          }
        })



        this.companyState=[]

        this.indexList.forEach(item=>{
          let listitem={
            type:item,
            list:[],
            listLen:'',
          }
          this.companyState.push(listitem)
        })

        this.companyList=this.companyState

        this.companyListchange(j)

      }else {

       this.companyList=this.companyListData

      }
    },



  }
}
</script>

<style scoped lang="scss">
.company-list{
  .input{
    padding:16px;
    ::v-deep{
      .van-search__content{
        background: #FFFFFF;
      }
    }
  }

  .van-index-bar{
    padding: 0 23px 14px;
  }

  .bar-item{

    .item-index{
      height: 50px;
      border-bottom: 1px solid #EEEEEE;
      ::v-deep{
        >.van-index-anchor{
          padding: 0;
          height: 50px;
          line-height: 50px;
          font-size: 16px;
          font-weight: bold;
          color: #000000;
          &::after{
            content: '';
            width: 335px;
            height: 1px;
            background: #EEEEEE;
            opacity: 1;
          }
        }


      }
    }

    ::v-deep{
      .van-cell::after{
        content: none;
      }
    }

    .item-cell{
      height: 50px;
      padding:0;
      border-bottom: 1px solid #EEEEEE;
      ::v-deep{
        .van-cell__title{
          height: 50px;
          >span{
            height: 50px;
            line-height: 50px;
            font-size: 16px;
            //font-weight: 500;
            color: #000000;
          }
        }

      }

    }
  }
}
</style>
